import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { CrowdWalletService } from './crowd-wallet.service';
import { IPermissionGuardModel } from '../models';

@Injectable()
export class PermissionGuard {
  constructor(
    private readonly router: Router,
    private readonly crowdWalletService: CrowdWalletService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userData = this.crowdWalletService.userDetail;

    const data = route.data.Permission as IPermissionGuardModel;
    if (
      !userData ||
      (userData && (!userData.email || !userData.access_token))
    ) {
      if (data.RedirectTo && data.RedirectTo !== undefined) {
        this.router.navigate([data.RedirectTo]);
      }

      return false;
    }
    if (this.crowdWalletService.isTokenExpired()) {
      this.crowdWalletService.deleteUserData();
      this.router.navigate([data.RedirectTo]);
      return false;
    }

    return true;
  }
}
