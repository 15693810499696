import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AgentTab } from '../models';

@Injectable({
  providedIn: 'root'
})
export class SharedStateService {
  private _fromLoginPage = new BehaviorSubject<boolean>(true); // Default value
  public fromLoginPage$ = this._fromLoginPage.asObservable();
  public _agentTabChangeSubject = new BehaviorSubject<AgentTab>(
    AgentTab.AllAgents
  );

  constructor() {}

  setFromLoginPage(value: boolean): void {
    this._fromLoginPage.next(value);
  }

  getFromLoginPage(): boolean {
    return this._fromLoginPage.getValue();
  }

  agentTabChanged(data: AgentTab) {
    this._agentTabChangeSubject.next(data);
  }
}
