import { ChannelType } from './design.model';

export enum MessageRole {
  user = 'user',
  amates = 'amates',
  system = 'system'
}
export interface IMessage {
  message: string;
  role: MessageRole;
  type: ChannelType;
  report?: IAmatesReport;
  suggested_replies?: string[];
  // optional filed
  hasButton?: boolean;
  messageList?: string[];
  buttonType?: MessageButtonType;
  conversation_id?: number;
  flag?: string;
  messages?: string[];
}

export enum MessageButtonType {
  switchtab = 'switchtab'
}

export interface IMessageHistory {
  created_at: string;
  id: number;
  message: string;
  role: MessageRole | 'bot';
  room_id: string;
  user_id: any;
  flag?: string;
}
export interface IMessageReport {
  report?: {
    stage: string;
    value: string;
    description: string;
  }[];
  progress?: number;
  finalized: boolean;
}
export interface IAmatesReport {
  [ChannelType.BUILD]: IMessageReport;
  [ChannelType.TRAIN]: IMessageReport;
}
